



















import Vue from 'vue'
import ForbidDanmakuCard from "@/components/ForbidDanmakuCard.vue"
import FocusArea from "@/components/FocusArea.vue"

export default Vue.extend({
  name: "Settings",
  components: {FocusArea, ForbidDanmakuCard},
  metaInfo: {
    title: '设定'
  },
  data() {
    return {
      inputValid: false,
      baseUrl: this.$store.state.baseUrl,
      userKey: this.$store.state.userKey,
      btnLoading: false,
      genUserKeyLoading: false,
    }
  },
  methods: {
    applySettings() {
      this.btnLoading = true
      this.$store.commit('setBaseUrl', this.baseUrl)
      this.$store.commit('setUserKey', this.userKey)
      this.$axios.defaults.baseURL = this.baseUrl
      this.$axios.defaults.headers['Authorization'] = this.userKey
      this.$swal.success('设定已更新').then(() => {
        this.btnLoading = false
      })
    },
    generateUserKey() {
      this.$swal.confirm('是否获取新的识别码并覆盖旧的？').then(res => {
        if (res.isConfirmed) {
          this.genUserKeyLoading = true
          this.$axios.post('user/generate').then(res => {
            this.userKey = res.data.data.token
            this.genUserKeyLoading = false
          })
        }
      })
    },
    validateBaseUrl() {
      if (!this.baseUrl.trim().length) {
        this.inputValid = false
        return '地址不可为空'
      }
      if (!this.isValidHttpUrl(this.baseUrl)) {
        this.inputValid = false
        return 'URL不合法'
      }
      if (!this.baseUrl.endsWith('/')) {
        this.inputValid = false
        return '地址需要以「/」结尾'
      }
      this.inputValid = true
      return true
    },
    isValidHttpUrl(string: string) {
      let url
      try {
        url = new URL(string)
      } catch (_) {
        return false
      }
      return url.protocol === "http:" || url.protocol === "https:"
    }
  }
})
