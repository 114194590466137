








import Vue from "vue"

export default Vue.extend({
  name: "PlayList",
  props: ['seriesHash', 'videos'],
  methods: {
    navigateToWatch(hash: string) {
      this.$router.push({name: 'Watch', params: {seriesHash: this.seriesHash, hash}})
    },
  }
})

