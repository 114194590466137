











import Vue from "vue"

export default Vue.extend({
  name: 'SearchBar',
  props: ['value'],
  data() {
    return {}
  },
})
