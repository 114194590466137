




























import Vue from "vue"
import {IBulletRaw, IDanmakuEpisode} from "@/types"
import {BulletOption} from "@nplayer/danmaku/dist/src/ts/danmaku/bullet"

export default Vue.extend({
  name: "DanmakuInsertBtn",
  props: ['seasonID', 'title'],
  data() {
    return {
      dialog: false,
      loading: true,
      danmakuEpisodeList: [] as IDanmakuEpisode[],
    }
  },
  methods: {
    openDialog() {
      this.dialog = true
      if (!this.danmakuEpisodeList.length) {
        this.getDanmakuEpisodeList()
      }
    },
    getDanmakuEpisodeList() {
      this.loading = true
      this.$axios.get('bullet/anime/'+this.seasonID).then(res => {
        this.danmakuEpisodeList = res.data.data
        this.loading = false
      })
    },
    insertDanmaku(danmakuEpisode: IDanmakuEpisode) {
      this.loading = true

      interface IPosMap {
        [key: number]: "scroll" | "top" | "bottom"
      }

      let posMap={
        1:'scroll',
        2:'scroll',
        3:'scroll',
        4:'bottom',
        5:'top',
        6:'top',
        7:'top',
        8:'scroll',
        9:'scroll',
      } as IPosMap
      this.$axios.get('bullet/bullet/'+danmakuEpisode.cid).then(res => {
        let arr = res.data.data as IBulletRaw[]
        let bulletsArr = [] as BulletOption[]
        for (let single of arr) {
          let time = single.time || 0
          let type = posMap[single.type || 1]
          let color = '#' + single.color?.toString(16) || 'ffffff'
          let text = single.text || ''
          bulletsArr.push({
            color,
            text,
            time,
            type
          })
        }
        bulletsArr.sort((a, b) => a.time - b.time)
        this.$emit('insertDanmaku', bulletsArr)
        this.loading = false
        this.dialog = false
      })
    }
  }
})

