





























import Vue from 'vue'
import FocusArea from "@/components/FocusArea.vue"
import {ISeries} from "@/types"
import SearchBar from "@/components/SearchBar.vue"

export default Vue.extend({
  name: 'Index',
  components: {SearchBar, FocusArea},
  metaInfo: {
    title: '主页'
  },
  activated() {
    if (!this.$store.state.baseUrl.length) {
      return
    }
    if (!this.pageInit) {
      this.pageInit = true
      this.initPage()
    }
  },
  data() {
    return {
      pageInit: false,
      searchText: '',
      series: [] as ISeries[],
      loading:false,
    }
  },
  computed: {
    filteredSeries(): ISeries[] {
      if (this.searchText.length === 0) {
        return this.series
      }
      return this.series.filter(item => {
        return item.name.includes(this.searchText)
      })
    }
  },
  methods: {
    initPage() {
      this.getSeriesList()
    },
    navigateToWatch(seriesHash: string, hash: string) {
      this.$router.push({name: 'Watch', params: {seriesHash, hash}})
    },
    getSeriesList() {
      this.loading=true
      this.$axios.get('video/list').then(res => {
        this.series = res.data.data
        this.loading=false
      })
    }
  }
})
