










































import Vue from "vue"
import FocusArea from "../components/FocusArea.vue"
import {IAnimeDetail, IBulletSeries, IEpisode} from "@/types"
import {MetaInfo} from "vue-meta"
import {EVENT, Player, PlayerOptions} from "nplayer"
import Danmaku from '@nplayer/danmaku'
import {BulletOption} from "@nplayer/danmaku/dist/src/ts/danmaku/bullet"
import SearchBar from "@/components/SearchBar.vue"
import DanmakuInsertBtn from "@/components/DanmakuInsertBtn.vue"
import ForbidDanmakuCard from "@/components/ForbidDanmakuCard.vue"
import * as vuex from 'vuex'
import PlayList from "@/components/PlayList.vue"
import * as qs from "qs"

export default Vue.extend({
  name: "Watch",
  components: {PlayList, ForbidDanmakuCard, DanmakuInsertBtn, SearchBar, FocusArea},
  props: ['hash', 'seriesHash'],
  metaInfo(): MetaInfo {
    return {
      title: (this.episodeDetail) ? this.episodeDetail.name + ' - ' + this.episodeDetail.seriesName : '观看影片'
    }
  },
  computed: {
    routerProps(): string {
      return this.hash + '-' + this.seriesHash
    },
    ...vuex.mapState(['forbidDanmakuList', 'customDanmakuOptions', 'baseUrl'])
  },
  data() {
    return {
      initWatchPending: false,
      episodeDetail: undefined as IEpisode | undefined,
      animeDetail: undefined as IAnimeDetail | undefined,
      player: undefined as Player | undefined,
      playerDefaultOptions: {
        controls: [
          ['play', 'volume', 'time', 'spacer', 'airplay', 'danmaku-settings', 'settings', 'web-fullscreen', 'fullscreen'],
          ['progress']
        ],
        plugins: [
          new Danmaku({
            autoInsert: false,
            ...this.$store.state.customDanmakuOptions
          })
        ]
      } as PlayerOptions,
      danmakuSourceList: [] as IBulletSeries[],
      danmakuListUnfiltered: [] as BulletOption[],
      danmakuListFiltered: [] as BulletOption[],
      danmakuSearchInput: '',
      danmakuSearchInputLoading: false,
      danmakuLoading: false,
      historyTimeLogger: undefined as number | undefined,
      firstPlay: true
    }
  },
  watch: {
    routerProps: {
      immediate: true,
      handler() {
        if (!this.player) {
          this.initWatchPending = true
        } else {
          this.initWatch()
        }
      }
    }
  },
  deactivated() {
    clearInterval(this.historyTimeLogger)
    this.$destroy()
  },
  methods: {
    navigateToIndex() {
      this.$router.push({name: 'Index'})
    },
    setPlayer(player: Player) {
      this.player = player
      if (this.initWatchPending) {
        this.initWatch()
        this.initWatchPending = false
      }
    },
    loadVideo() {
      let videoUrl = this.baseUrl + 'video/stream/' + this.episodeDetail?.hash
      this.player!.updateOptions({src: videoUrl})
    },
    initWatch() {
      clearInterval(this.historyTimeLogger)
      this.animeDetail = undefined
      this.episodeDetail = undefined
      this.danmakuListFiltered = []
      this.danmakuListUnfiltered = []
      this.firstPlay = true
      this.player!.danmaku.resetItems([])
      this.danmakuSearchInputLoading = true
      this.$axios.get('user/searchText/' + this.seriesHash).then(res => {
        this.danmakuSearchInput = res.data.data
        this.danmakuSearchInputLoading = false
        this.getDanmakuSourceList()
      })
      this.$axios.get("video/detail/" + this.seriesHash).then(res => {
        this.animeDetail = res.data.data
        this.episodeDetail = this.animeDetail!.videos.find(video => video.hash === this.hash)
        this.loadVideo()
        this.player!.on(EVENT.ERROR, () => {
          this.loadVideo()
        })
        this.player!.on(this.player!.danmaku.EVENT.DANMAKU_UPDATE_OPTIONS, () => {
          this.$store.commit('setCustomDanmakuOptions', this.player!.danmaku.opts)
        })
        this.player!.on(EVENT.PLAY, () => {
          if (!this.firstPlay) {
            return
          }
          this.firstPlay = false
          this.$axios.get('user/progress/' + this.hash).then(res => {
            let progress = parseInt(res.data.data)
            console.log('progress: ' + progress)
            if (progress) {
              this.player!.seek(progress)
            }
            this.historyTimeLogger = setInterval(() => {
              this.$axios.post('user/progress', qs.stringify({
                time: this.player!.currentTime,
                hash: this.hash,
              }))
            }, 5000)
          })
        })
      })
    },
    userEmitDanmakuSearch() {
      if (this.$store.state.userKey.length) {
        this.$axios.post('user/searchText', qs.stringify({
          hash: this.seriesHash,
          searchText: this.danmakuSearchInput,
        }))
      }
      this.getDanmakuSourceList()
    },
    getDanmakuSourceList() {
      this.danmakuLoading = true
      this.$axios.get('bullet/search/' + encodeURI(this.danmakuSearchInput)).then(res => {
        this.danmakuSourceList = res.data.data
        this.danmakuLoading = false
      })
    },
    insertDanmaku(danmakuList: BulletOption[]) {
      this.danmakuListUnfiltered = [...this.danmakuListUnfiltered, ...danmakuList].sort((a, b) => a.time - b.time)
      this.resetDanmaku()
    },
    resetDanmaku() {
      let danmakuList = this.danmakuListUnfiltered.filter(value => {
        for (let regexp of this.forbidDanmakuList) {
          if (regexp.test(value.text)) {
            return false
          }
        }
        return true
      })
      this.danmakuListFiltered = danmakuList
      this.player?.danmaku.resetItems(danmakuList)
    }
  }
})
