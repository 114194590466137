






















import Vue from 'vue'

export default Vue.extend({
  name: 'App',
  metaInfo: {
    titleTemplate: '%s | Anime Hostess'
  },
  data() {
    return {}
  },
  mounted() {
    if (!this.$store.state.baseUrl.length) {
      this.navigateToSettings()
      return
    }
  },
  methods:{
    navigateToSettings(){
      this.$router.push({name: 'Settings'})
    }
  }
})
