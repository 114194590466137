















import Vue from "vue"

export default Vue.extend({
  name: "ForbidDanmakuCard",
  data() {
    return {
      inputText: ''
    }
  },
  computed: {
    forbidDanmakuList: {
      set(value: RegExp[]) {
        this.$store.commit('setForbidDanmakuList', value)
      },
      get(): RegExp[] {
        return this.$store.state.forbidDanmakuList
      }
    }
  },
  methods: {
    addDanmaku() {
      for (let danmaku of this.forbidDanmakuList) {
        if (danmaku.toString() === '/' + this.inputText + '/') {
          this.$swal.error('已存在此屏蔽词')
          return
        }
      }
      let regexp: RegExp
      try {
        regexp = new RegExp(this.inputText)
      } catch (_e) {
        this.$swal.error('正则表达式不合法')
        return
      }
      let arr: RegExp[] = this.forbidDanmakuList.slice(0)
      arr.push(regexp)
      this.forbidDanmakuList = arr
      this.inputText = ''
      this.$emit('modify')
    },
    removeDanmaku(regExp: RegExp) {
      let arr: RegExp[] = this.forbidDanmakuList.slice(0)
      arr = arr.filter(value => value.toString() !== regExp.toString())
      this.forbidDanmakuList = arr
      this.$emit('modify')
    }
  }
})
